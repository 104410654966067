import { ref, watch, computed, onMounted } from "@vue/composition-api";
import store from "@/store";
import axiosIns from "@/libs/axios";

// Notification
import { useToast } from "vue-toastification/composition";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import ability from "@/libs/acl/ability";

export default function useRepaymentsList() {
  // Use toast
  const toast = useToast();

  const refRepaymentsListTable = ref(null);

  // Table Handlers
  const tableColumns = [
      // { key: "receipt_number", label: "收據編號", sortable: true },
      { key: "latest_receipt_date", label: "收據日期", sortable: true },
      { key: "client_loan_id", label: "合約編號", sortable: true },
      { key: "pay_date", label: "供款日", sortable: true },
      { key: "client_loan.period", label: "總期數", },
      { key: "period", label: "期數", sortable: true },
      { key: "initial_principal", label: "期初本金", sortable: true },
      { key: "principal", label: "每期本金", sortable: true },
      { key: "interest", label: "利息", sortable: true },
      { key: "payment_status", label: "合約狀態", sortable: true },
      { key: "show_details", label: "還款詳情", sortable: true },
      { key: "client_loan.name_cn", label: "中文姓名", sortable: true },
      { key: "client.id_number", label: "身分證", sortable: true },
      { key: "payment_amount", label: "供款", sortable: true },
      { key: "additional_fee", label: "附加費", sortable: true },
      { key: "postpone_fee", label: "延期費", sortable: true },
      { key: "defer_fee", label: "遲交費", sortable: true },
      { key: "actual_amount", label: "收據總銀碼", sortable: true },
      { key: "actual_paid_amount", label: "已付", sortable: true },
      { key: "remaining_amount", label: "餘額", sortable: true },
      { key: "loan_type_id", label: "借貸類型", sortable: true },
      { key: "payments_receive_method_id", label: "付款方式", sortable: true },
      { key: "additional_remark", label: "附加備註", sortable: true },
      { key: "payment_date", label: "入數日期及時間", sortable: true },
      { key: "client_loan.loan_date", label: "貸款日期", sortable: true },
      { key: "actions", stickyColumn: true, label: "操作" },
  ]

  const tablePaymentsColumns = [
    { key: "id", label: "編號", sortable: true },
    { key: "payment_receipts", label: "收據文件", sortable: true },
    { key: "payment_documents", label: "其他文件", sortable: true },
    { key: "amount", label: "還款金額", sortable: true },
    { key: "payment_date", label: "還款日期", sortable: true },
    { key: "receive_method_id", label: "收款方式", sortable: true },
    { key: "receive_account_id", label: "收款帳號", sortable: true },
    { key: "receipt_number", label: "收據編號", sortable: true },
    { key: "receipt_date", label: "收據日期", sortable: true },
    { key: "remark", label: "備註", sortable: true },
    { key: "edit",  label: "修改"},
  ]
  // stickyColumn: true,
  const perPage = ref(10);
  const totalRow = ref(0);
  const currentPage = ref(1);
  const perPageOptions = [10, 25, 50, 100];
  const searchQuery = ref("");
  const sortBy = ref("pay_date");
  const isSortDirDesc = ref(false);

  const dataMeta = computed(() => {
    const localItemsCount = refRepaymentsListTable.value
      ? refRepaymentsListTable.value.localItems.length
      : 0;
    return {
      from: perPage.value * (currentPage.value - 1) + (localItemsCount ? 1 : 0),
      to: perPage.value * (currentPage.value - 1) + localItemsCount,
      of: totalRow.value,
    };
  });

  const refetchData = () => {
    refRepaymentsListTable.value.refresh();
  };

  const repaymentsList = ref(null);

  const filter_payment_make_today = ref(0); //當日已供款
  const filter_late_payment = ref(0); //遲交
  const filter_unpaid = ref(0); //尚未還款
  const filter_all_records = ref(1); //全部紀錄
  const filter_loan_date_from = ref(null);
  const filter_loan_date_to = ref(null);
  const filter_pay_date_from = ref(null);
  const filter_pay_date_to = ref(null);
  const filter_receipt_date_from = ref(null);
  const filter_receipt_date_to = ref(null);
  const filter_is_paid = ref(0); 
  const idFilter = ref(null);

  watch(
    [currentPage, perPage, searchQuery, 
      filter_payment_make_today, //當日已供款
      filter_late_payment, //遲交
      filter_unpaid, //尚未還款
      filter_all_records, //全部紀錄,
      filter_is_paid,
      filter_pay_date_from,
      filter_pay_date_to,
      filter_loan_date_from,
      filter_loan_date_to,
      filter_receipt_date_from,
      filter_receipt_date_to,
      idFilter,
    ],
    () => {

      refetchData();
    }
  );

  const today = new Date()
  // const oneYear;
  // oneYear.setFullYear(today.getFullYear()+1);
  const year = today.getFullYear();
  const month = String(today.getMonth() + 1).padStart(2, '0'); // Months are zero-based, so we add 1
  const day = String(today.getDate()).padStart(2, '0');

  // Format the date as "yyyy-mm-dd"
  const formattedDate = `${year}-${month}-${day}`;
  
  const is_inited = ref(false)
  const fetchRepayments = (ctx, callback) => {
    if(!is_inited.value){
      filter_pay_date_to.value = formattedDate
      is_inited.value = true
    }
    store
      .dispatch("repayment/fetchRepayments", {
        q: searchQuery.value,
        perPage: perPage.value,
        page: currentPage.value,
        sortBy: sortBy.value,
        sortDesc: isSortDirDesc.value,
        id_number: idFilter.value,
        payment_make_today : filter_payment_make_today.value,
        is_paid : filter_is_paid.value,
        late_payment : filter_late_payment.value,
        unpaid : filter_unpaid.value,
        all_records : filter_all_records.value,
        dateFrom: filter_pay_date_from.value,
        dateTo: filter_pay_date_to.value,
        loanDateFrom: filter_loan_date_from.value,
        loanDateTo: filter_loan_date_to.value,
        receiptDateFrom: filter_receipt_date_from.value,
        receiptDateTo: filter_receipt_date_to.value
      })
      .then((response) => {
        const { payment_schedules, total } = response.data;
        callback(payment_schedules);
        repaymentsList.value = payment_schedules
        totalRow.value = total;
      })
      .catch((err) => {
        console.log(err);
        toast({
          component: ToastificationContent,
          props: {
            title: "獲取貸款清單時出錯",
            icon: "AlertTriangleIcon",
            variant: "danger",
          },
        });
      });
  }; 
  const receiveMethodOptions = ref([]);
  const receiveAccountOptions = ref([]);
  const contractStatusesOptions = ref([]);
  const loanTypeOptions = ref([]);

  const refetchOption = () => {
    store
      .dispatch("app/fetchOptionList", {
        receive_method: true,
        contract_status: true,
        loan_type: true,
      })
      .then((response) => {
        receiveMethodOptions.value = response.data.receive_methods;
        contractStatusesOptions.value = response.data.contract_statuses;
        loanTypeOptions.value = response.data.loan_types;
      });

    store
      .dispatch("repayment/fetchReceiveAccounts")
      .then((response) => {
        receiveAccountOptions.value = response.data.receive_accounts
      })
      .catch((error) => {
        if (error.response.status === 404) {
          receiveAccountOptions.value = undefined;
        }
      });
  };


  onMounted(() => {
    refetchOption();
    // refetchData();
  });

  return {
    fetchRepayments,
    tableColumns,
    tablePaymentsColumns,
    perPage,
    totalRow,
    currentPage,
    perPageOptions,
    searchQuery,
    sortBy,
    isSortDirDesc,
    dataMeta,
    repaymentsList,
    refRepaymentsListTable,
    ability,
    refetchData,
    receiveMethodOptions,
    receiveAccountOptions,
    contractStatusesOptions,
    loanTypeOptions,
    idFilter,
    filter_is_paid,
    filter_payment_make_today, //當日已供款
    filter_late_payment, //遲交
    filter_unpaid, //尚未還款
    filter_all_records, //全部紀錄
    filter_pay_date_from,
    filter_pay_date_to,
    filter_loan_date_from,
    filter_loan_date_to,
    filter_receipt_date_from,
    filter_receipt_date_to,
  };
}
