<template>
  <div class="">
    <modal-filter
      :filter_pay_date_from.sync="filter_pay_date_from"
      :filter_pay_date_to.sync="filter_pay_date_to"
      :filter_loan_date_from.sync="filter_loan_date_from"
      :filter_loan_date_to.sync="filter_loan_date_to"
      :filter_receipt_date_from.sync="filter_receipt_date_from"
      :filter_receipt_date_to.sync="filter_receipt_date_to"
      :filter_is_paid.sync="filter_is_paid"
      :idFilter.sync="idFilter"
      :contractStatusesOptions="contractStatusesOptions"
    />
    <modal-print-receipt :print_list="print_list"></modal-print-receipt>
    <modal-contract-status
      v-if="contractStatusesOptions && contractStatusesOptions.length > 0"
      :contract_id="client_loan_id"
      :contract_status_id="contract_status_id"
      :contractStatusesOptions="contractStatusesOptions"
      @refetch-data="refetchData"
    ></modal-contract-status>

    <modal-image-viewer
      id="1"
      :image-list="previewImage"
      :selected_file="0"
    ></modal-image-viewer>

    <modal-pdf :pdfUrl="pdfUrl"></modal-pdf>
    <b-card class="">
      <div class="d-flex flex-wrap align-items-center justify-content-between">
        <div class="d-flex mb-1 mb-sm-0 align-items-center">
          <h4 class="font-weight-bolder ml-1 text-nowrap mb-0">
            <font-awesome-icon
              class="mr-50"
              icon="fa-solid fa-money-bill-alt"
            />
            還款
          </h4>
          <div class="d-flex flex-wrap ml-3 align-items-center">
            <div class="d-flex align-items-center">
              <label class="mb-0">顯示</label>
              <v-select
                v-model="perPage"
                :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                :options="perPageOptions"
                :clearable="false"
                class="per-page-selector d-inline-block mx-50"
              />
              <label class="mb-0">條目</label>
            </div>

            <div class="ml-3 d-flex flex-wrap">
              <div class="d-flex">
                <span class="mr-50">當日已供款</span>
                <b-form-checkbox
                  :checked="filter_payment_make_today == 1"
                  inline
                  v-model="filter_payment_make_today"
                  value="1"
                  @change="
                    changeFilterStatus(
                      'filter_payment_make_today',
                      filter_payment_make_today
                    )
                  "
                ></b-form-checkbox>
              </div>

              <div class="d-flex">
                <span class="mr-50">遲交</span>
                <b-form-checkbox
                  :checked="filter_late_payment == 1"
                  inline
                  v-model="filter_late_payment"
                  value="1"
                  @change="
                    changeFilterStatus(
                      'filter_late_payment',
                      filter_late_payment
                    )
                  "
                >
                </b-form-checkbox>
              </div>

              <div class="d-flex">
                <span class="mr-50">尚未還款</span>
                <b-form-checkbox
                  :checked="filter_unpaid == 1"
                  inline
                  v-model="filter_unpaid"
                  value="1"
                  @change="changeFilterStatus('filter_unpaid', filter_unpaid)"
                >
                </b-form-checkbox>
              </div>
              <div class="d-flex">
                <span class="mr-50">全部紀錄</span>
                <b-form-checkbox
                  :checked="filter_all_records == 1"
                  inline
                  v-model="filter_all_records"
                  value="1"
                  @change="
                    changeFilterStatus('filter_all_records', filter_all_records)
                  "
                >
                </b-form-checkbox>
              </div>
            </div>
          </div>
        </div>
        <div
          class="d-flex mobile-w100 align-items-center flex-sm-nowrap flex-wrap"
        >
          <b-form-input
            v-model="searchQuery"
            style="width: 300px"
            class="search-form mb-1 mb-sm-0 mr-sm-1"
            placeholder="搜尋還款"
          />
          <b-button
            variant="primary"
            class="mr-sm-1 mobile-w100 mb-1 mb-sm-0 mr-sm-1 d-flex align-items-center text-nowrap"
            @click="$bvModal.show('modal-filter')"
            ><feather-icon
              class="mr-1"
              icon="filterIcon"
              size="18"
            ></feather-icon
            >篩選</b-button
          >
        </div>
      </div>
    </b-card>
    <b-card no-body class="card-statistic">
      <b-overlay
        :show="loading"
        spinner-variant="primary"
        spinner-type="grow"
        spinner-small
        rounded="sm"
      >
      <b-card-body class="p-2">
        <b-table
          ref="refRepaymentsListTable"
          class="position-relative sticky-rtl-tb"
          :items="fetchRepayments"
          responsive
          :fields="tableColumns"
          primary-key="id"
          :sort-by.sync="sortBy"
          show-empty
          empty-text="未找到匹配的記錄"
          :sort-desc.sync="isSortDirDesc"
          :tbody-tr-class="rowClass"
        >
          <template #cell(receipt_number)="data">
            <b-link
              class="color1"
              v-if="ability.can('update', 'repayment') || true"
              :to="{
                name: 'repayment-edit',
                params: {
                  contract_id: data.item.client_loan_id,
                  id: data.item.id,
                },
              }"
            >
              <ul class="m-0">
                <li
                  class="list-style-none text-left"
                  v-for="(payment, index) of getNewPayemnt(
                    data.item.payments,
                    'receipt_number'
                  )"
                  :key="`receipt_number${index}`"
                >
                  收據({{ index + 1 }}): {{ payment }}
                </li>
              </ul>
            </b-link>
          </template>

          <template #cell(latest_receipt_date)="data">
            <ul class="m-0 p-0">
              <li
                class="list-style-none text-left"
                v-for="(payment, index) of getNewPayemnt(
                  data.item.payments,
                  'receipt_date'
                )"
                :key="`receipt_date${index}`"
              >
                日期({{ index + 1 }}): {{ payment }}
              </li>
            </ul>
          </template>

          <template #cell(client_loan_id)="data">
            <b-link
              class="color1"
              v-if="ability.can('update', 'contracts') || true"
              :to="{
                name: 'contracts-edit',
                params: { id: data.item.client_loan_id },
              }"
            >
              {{ data.item.client_loan && data.item.client_loan.contract_number }}
            </b-link>
          </template>

          <template #cell(initial_principal)="data">
            {{ Math.round(Number(data.item.initial_principal)) }}
          </template>

          <template #cell(principal)="data">
            {{ Math.round(Number(data.item.principal)) }}
          </template>

          <template #cell(interest)="data">
            {{ Math.round(Number(data.item.interest)) }}
          </template>

          <template #cell(payment_amount)="data">
            {{ Math.round(Number(data.item.payment_amount)) }}
          </template>

          <template #cell(additional_fee)="data">
            {{ Math.round(Number(data.item.additional_fee)) }}
          </template>

          <template #cell(postpone_fee)="data">
            {{
              Math.round(
                Number(data.item.postpone_fee * data.item.postpone_day)
              )
            }}
          </template>

          <template #cell(defer_fee)="data">
            {{ Math.round(Number(data.item.defer_fee * data.item.defer_day)) }}
          </template>

          <template #cell(payment_date)="data">
            <ul class="mb-0">
              <li
                class="list-style-none text-left"
                v-for="(payment, index) of getNewPayemnt(
                  data.item.payments,
                  'payment_date'
                )"
                :key="`payment_date${index}`"
              >
                ({{ index + 1 }}): {{ payment }}
              </li>
            </ul>
          </template>

          <template #cell(client.id_number)="data">
            <span
              class="id-number color1 cursor-pointer"
              @click="
                setIdNumber(data.item.client && data.item.client.id_number)
              "
              >{{ data.item.client && data.item.client.id_number }}</span
            >
          </template>

          <template #cell(remaining_amount)="data">
            <div
              v-if="
                Number(data.item.actual_amount) -
                  Number(data.item.actual_paid_amount) >
                0
              "
            >
              {{
                Math.round(
                  Number(data.item.actual_amount) -
                    Number(data.item.actual_paid_amount)
                )
              }}
            </div>
            <div v-else>0</div>
          </template>

          <!-- <template #cell(contract_status_id)="data">
            <a
              href="javascript:void(0)"
              class="colorBlack"
              @click="triggerContractStatus(data.item)"
            >
              <span
                v-if="
                  data.item.client_loan && data.item.client_loan.contract_status
                "
                >{{ data.item.client_loan.contract_status.name }}</span
              >
              <font-awesome-icon class="ml-50" icon="fa-solid fa-pen" />
            </a>
          </template> -->

          <template #cell(show_details)="row">
            <b-button
              v-if="row.item.payments && row.item.payments.length > 0"
              size="sm"
              @click="row.toggleDetails"
              class="mr-2"
            >
              {{ row.detailsShowing ? "隱藏" : "顯示" }}
            </b-button>
            <span v-else> - - </span>
          </template>
          <template #row-details="row">
            <div class="d-flex justify-content-start align-items-center py-2">
              <b>可按文件以放大觀看</b>
            </div>
            <b-table
              class="position-relative sticky-rtl-tb"
              :items="row.item.payments"
              responsive
              :fields="tablePaymentsColumns"
              primary-key="id"
            >
              <template #cell(id)="data">
                <b-link
                  class="color1"
                  v-if="ability.can('update', 'repayment') || true"
                  :to="{
                    name: 'repayment-edit',
                    params: {
                      contract_id: row.item.client_loan_id,
                      id: row.item.id,
                    },
                  }"
                >
                  {{ data.item.id }}
                </b-link>
              </template>

              <template #cell(receive_method_id)="data">
                {{
                  receiveMethodOptions &&
                  receiveMethodOptions.length > 0 &&
                  receiveMethodOptions.filter(
                    (ele) => ele.id == data.item.receive_method_id
                  )[0].name
                }}
              </template>
              <template #cell(receive_account_id)="data">
                <div v-if="data.item.receive_account_id">
                  {{
                    receiveAccountOptions &&
                    receiveAccountOptions.length > 0 &&
                    receiveAccountOptions.filter(
                      (ele) => ele.id == data.item.receive_account_id
                    )[0].account_number
                  }}
                </div>
              </template>

              <template #cell(payment_receipts)="data">
                <ul class="table-image-group">
                  <li class="">
                    <!-- :key="doc.id"
                    v-for="doc of data.item.payment_documents" -->
                    <a
                      href="javascript:void(0)"
                      @click="tirggerViewer(data.item.payment_receipts)"
                      v-if="
                        data.item.payment_receipts &&
                        data.item.payment_receipts.length > 0
                      "
                    >
                      <embed
                        v-if="
                          data.item.payment_receipts[0].original_image_path.indexOf(
                            'pdf'
                          ) > -1
                        "
                        class="item"
                        :src="data.item.payment_receipts[0].image_path"
                        type="application/pdf"
                      />
                      <img
                        v-else
                        class="item"
                        :src="data.item.payment_receipts[0].image_path"
                        alt="image slot"
                      />
                    </a>
                    <template v-else> - - </template>
                  </li>
                </ul>
              </template>

              <template #cell(payment_documents)="data">
                <ul class="table-image-group">
                  <li class="">
                    <!-- :key="doc.id"
                    v-for="doc of data.item.payment_documents" -->
                    <a
                      href="javascript:void(0)"
                      @click="tirggerViewer(data.item.payment_documents)"
                      v-if="
                        data.item.payment_documents &&
                        data.item.payment_documents.length > 0
                      "
                    >
                      <embed
                        v-if="
                          data.item.payment_documents[0].original_image_path.indexOf(
                            'pdf'
                          ) > -1
                        "
                        class="item"
                        :src="data.item.payment_documents[0].image_path"
                        type="application/pdf"
                      />
                      <img
                        v-else
                        class="item"
                        :src="data.item.payment_documents[0].image_path"
                        alt="image slot"
                      />
                    </a>
                    <template v-else> - - </template>
                  </li>
                </ul>
              </template>

              <template #cell(amount)="data">
                {{ Math.round(Number(data.item.amount)) }}
              </template>

              <template #cell(remark)="data">
                <span>{{
                  data.item.remark && data.item.remark !== "undefined"
                    ? data.item.remark
                    : "- -"
                }}</span>
              </template>

              <template
                #cell(edit)="data"
                v-if="ability.can('update', 'repayment') || true"
              >
              <div class="d-flex align-items-center justify-content-center">
                <!-- <a href="javascript:void(0)" @click="printReceipt(data.item.payment_receipts)">
                  <font-awesome-icon class="" icon="fa-solid fa-print" />
                </a>
                <span class="mx-50">|</span> -->
                <a href="javascript:void(0)" @click="exportReceipt(data.item.id)">
                  <feather-icon
                    icon="DownloadIcon"
                    size="18"
                  />
                </a>
                <span class="mx-50">|</span>
                <b-link
                  :to="{
                    name: 'repayment-edit',
                    params: {
                      contract_id: row.item.client_loan_id,
                      id: row.item.id,
                    },
                  }"
                >
                  <font-awesome-icon icon="fa-solid fa-pen" />
                </b-link>
              </div>
              </template>
            </b-table>
          </template>

          <!-- Column: is_paid -->
          <!-- <template #cell(is_paid)="data">
            <span v-if="data.item.is_paid">已還清</span>
            <span v-else-if="data.item.defer_fee">遲交中</span>
            <span v-else>供款中</span>
            <feather-icon
              v-if="data.item.is_paid"
              icon="CheckIcon"
              size="18"
              class="mr-50 text-success"
              
            />
          </template> -->

          <template #cell(loan_type_id)="data">
            {{
              loanTypeOptions &&
              loanTypeOptions.length > 0 &&
              data.item.client_loan &&
              loanTypeOptions.filter(
                (ele) => ele.id == data.item.client_loan.loan_type_id
              )[0].name
            }}
          </template>

          <template #cell(payments_receive_method_id)="data">
            <div
              v-if="
                receiveMethodOptions &&
                receiveMethodOptions.length > 0 &&
                data.item.payments &&
                data.item.payments.length > 0
              "
            >
              {{
                receiveMethodOptions.filter(
                  (ele) => ele.id == data.item.payments[0].receive_method_id
                )[0].name
              }}
            </div>
          </template>

          <template #cell(actions)="data">
            <b-link
              v-if="ability.can('update', 'repayment') || true"
              :to="{
                name: 'repayment-edit',
                params: {
                  contract_id: data.item.client_loan_id,
                  id: data.item.id,
                },
              }"
            >
              <feather-icon icon="EditIcon" />
              <span class="align-middle ml-50">還款</span>
            </b-link>

            <!-- <b-dropdown-item
                v-if="ability.can('delete', 'repayment') || true"
                @click="removeItem(id)"
              >
                <feather-icon icon="Trash2Icon" />
                <span class="align-middle ml-50">刪除記錄</span>
              </b-dropdown-item> -->
          </template>
        </b-table>
        <div class="mx-2 mb-2">
          <b-row>
            <b-col
              cols="12"
              sm="6"
              class="d-flex align-items-center justify-content-center justify-content-sm-start"
            >
              <span class="text-muted"
                >在{{ dataMeta.of }}個記錄中，正在顯示 {{ dataMeta.from }} 至
                {{ dataMeta.to }}個記錄</span
              >
            </b-col>
            <!-- Pagination -->
            <b-col
              cols="12"
              sm="6"
              class="d-flex align-items-center justify-content-center justify-content-sm-end"
            >
              <b-pagination
                v-model="currentPage"
                :total-rows="totalRow"
                :per-page="perPage"
                first-number
                last-number
                class="mb-0 mt-1 mt-sm-0"
                prev-class="prev-item"
                next-class="next-item"
              >
                <template #prev-text>
                  <feather-icon icon="ChevronLeftIcon" size="18" />
                </template>
                <template #next-text>
                  <feather-icon icon="ChevronRightIcon" size="18" />
                </template>
              </b-pagination>
            </b-col>
          </b-row>
        </div>

      </b-card-body>
      </b-overlay>
    </b-card>
  </div>
</template>
    
<script>
import {
  BCard,
  BCardBody,
  BRow,
  BCol,
  BFormGroup,
  BFormInput,
  BFormCheckbox,
  BButton,
  BTable,
  BMedia,
  BAvatar,
  BLink,
  BBadge,
  BOverlay,
  BDropdown,
  BDropdownItem,
  BPagination,
} from "bootstrap-vue";
import vSelect from "vue-select";
import store from "@/store";
import { ref, onUnmounted } from "@vue/composition-api";
import ModalFilter from "../modal/ModalFilter";
import ModalImageViewer from "@/layouts/components/file/ModalImageViewer.vue";
import useRepaymentsList from "./useRepaymentsList";
import repaymentStoreModule from "../repaymentStoreModule";
import ModalContractStatus from "../modal/ModalContractStatus.vue";
import ModalPrintReceipt from "../modal/ModalPrintReceipt.vue";
import ModalPdf from "@/layouts/components/file/ModalPdf.vue";

export default {
  components: {
    BCard,
    BCardBody,
    BRow,
    BCol,
    BFormGroup,
    BFormInput,
    BFormCheckbox,
    BButton,
    BTable,
    BMedia,
    BAvatar,
    BLink,
    BBadge,
    BOverlay,
    BDropdown,
    BDropdownItem,
    BPagination,
    vSelect,
    ModalFilter,
    ModalImageViewer,
    ModalPrintReceipt,
    ModalContractStatus,
    ModalPdf
  },
  data() {
    return {
      client_loan_id: null,
      contract_status_id: null,
      print_list:null,
      pdfUrl:null,
      loading:false
    };
  },
  methods: {
    exportReceipt(id){
      this.loading = true;
      this.$store
        .dispatch("repayment/exportPaymentReceipt", { id })
        .then((response) => {
          this.loading = false;
          const filepdf = new Blob([response.data], {
            type: "application/pdf",
          });
          const fileURL = URL.createObjectURL(filepdf);
          this.pdfUrl = fileURL;
          this.$bvModal.show("modal-pdf");
        })
        .catch((error) => {
          this.loading = false;
          console.log({ error });
        });
    },
    rowClass(item) {
      if(item){
        // const date = new Date(item.pay_date).getTime()
        // const today = new Date().getTime()
        // return (!item.is_paid && (date < today) ? true : false) ? 'defer_peroid' : ''
        return (item.payment_status == "遲交中" || item.payment_status == "遲交已收回") ? 'defer_peroid' : ''
      }else{
        return null
      }
    },
    setIdNumber(id) {
      if (this.idFilter == id) {
        this.idFilter = null;
      } else {
        this.idFilter = id;
      }
    },
    getNewPayemnt(arr, key) {
      return arr.map((ele) => (ele[key] ? ele[key] : "- -"));
    },
    triggerContractStatus(item) {
      this.client_loan_id = item.client_loan_id;
      this.contract_status_id = item.client_loan.contract_status_id;
      this.$bvModal.show("modal-contract-status");
    },
    removeItem(id) {
      // check number of record
      this.$swal({
        title: "你確定嗎?",
        text: "您的決定是最終決定，您將無法取回這記錄。",
        showCancelButton: true,
        confirmButtonText: "刪除",
        cancelButtonText: "取消",
        customClass: {
          confirmButton: "btn btn-primary",
          cancelButton: "btn btn-outline-danger ml-1",
        },
        buttonsStyling: false,
      }).then((result) => {
        if (result.value) {
          this.$store
            .dispatch("/repayment/deleteRepayment", { id })
            .then((response) => {
              this.$swal({
                text: response.data.message,
                icon: "success",
                customClass: {
                  confirmButton: "btn btn-primary",
                },
                showClass: {
                  popup: "animate__animated animate__bounceIn",
                },
                buttonsStyling: false,
              });
              this.refetchData();
            })
            .catch((error) => {
              this.$swal({
                text: JSON.stringify(error.response.data.message),
                icon: "error",
                customClass: {
                  confirmButton: "btn btn-primary",
                },
                showClass: {
                  popup: "animate__animated animate__bounceIn",
                },
                buttonsStyling: false,
              });
            });
        }
      });
    },
    tirggerViewer(files) {
      this.previewImage = files.map((ele) => {
        return {
          id: ele.id,
          src: ele.image_path,
          type:
            ele.original_image_path.indexOf("pdf") > -1
              ? "application/pdf"
              : "image/jpeg",
          is_exist: 1,
        };
      });
      this.$bvModal.show(`modal-image-viewer1`);
    },
    changeFilterStatus(key, val) {
      this.filter_payment_make_today = 0; //當日已供款
      this.filter_late_payment = 0; //遲交
      this.filter_unpaid = 0; //尚未還款
      this.filter_all_records = 0; //全部紀錄
      val ? (this[key] = 1) : (this[key] = 0);
      if(key == 'filter_unpaid'){
          const today = new Date()
          // const oneYear;
          // oneYear.setFullYear(today.getFullYear()+1);
          const year = today.getFullYear();
          const month = String(today.getMonth() + 1).padStart(2, '0'); // Months are zero-based, so we add 1
          const day = String(today.getDate()).padStart(2, '0');

          // Format the date as "yyyy-mm-dd"
          const formattedDate = `${year}-${month}-${day}`;

          this.filter_pay_date_from = formattedDate
          this.filter_pay_date_to = formattedDate
      }
    },
    printReceipt(print_list){
      this.print_list = print_list;
      this.$bvModal.show("modal-print-receipt");
    }
  },
  setup() {
    const USER_APP_STORE_MODULE_NAME = "repayment";

    // Register module
    if (!store.hasModule(USER_APP_STORE_MODULE_NAME))
      store.registerModule(USER_APP_STORE_MODULE_NAME, repaymentStoreModule);

    // UnRegister on leave
    onUnmounted(() => {
      if (store.hasModule(USER_APP_STORE_MODULE_NAME))
        store.unregisterModule(USER_APP_STORE_MODULE_NAME);
    });

    const previewImage = ref([]);

    const {
      fetchRepayments,
      tableColumns,
      tablePaymentsColumns,
      perPage,
      totalRow,
      currentPage,
      perPageOptions,
      searchQuery,
      sortBy,
      isSortDirDesc,
      dataMeta,
      repaymentsList,
      refRepaymentsListTable,
      ability,
      refetchData,
      receiveMethodOptions,
      receiveAccountOptions,
      contractStatusesOptions,
      loanTypeOptions,
      idFilter,
      filter_is_paid,
      filter_payment_make_today, //當日已供款
      filter_late_payment, //遲交
      filter_unpaid, //尚未還款
      filter_all_records, //全部紀錄
      filter_pay_date_from,
      filter_pay_date_to,
      filter_loan_date_from,
      filter_loan_date_to,
      filter_receipt_date_from,
      filter_receipt_date_to,
    } = useRepaymentsList();

    return {
      fetchRepayments,
      tableColumns,
      tablePaymentsColumns,
      perPage,
      totalRow,
      currentPage,
      perPageOptions,
      searchQuery,
      sortBy,
      isSortDirDesc,
      dataMeta,
      repaymentsList,
      refRepaymentsListTable,
      ability,
      refetchData,
      receiveMethodOptions,
      receiveAccountOptions,
      contractStatusesOptions,
      loanTypeOptions,
      idFilter,
      filter_is_paid,
      filter_payment_make_today, //當日已供款
      filter_late_payment, //遲交
      filter_unpaid, //尚未還款
      filter_all_records, //全部紀錄
      filter_pay_date_from,
      filter_pay_date_to,
      filter_loan_date_from,
      filter_loan_date_to,
      filter_receipt_date_from,
      filter_receipt_date_to,
      previewImage,
    };
  },
};
</script>
    
<style lang="scss" scoped>
.per-page-selector {
  width: 90px;
}

// @media screen and (max-width: 768px) {

// }
// @media screen and (max-width: 575px) {

// }
</style>
    
<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";
@import "@/assets/scss/variables/_variables.scss";

</style>
    