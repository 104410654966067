<template>
  <b-modal
    id="modal-filter"
    ok-title="Submit"
    cancel-variant="outline-secondary"
    scrollable
    title=""
    title-class="font-weight-bolder"
    body-class="common_modal modal_visible"
    hide-header
    hide-footer
    no-close-on-backdrop
    size="sm"
    hide-header-close
  >
    <div>
      <h3 class="mt-1 mb-2 d-flex justify-content-center align-items-center">
        <font-awesome-icon class="mr-50" icon="fa-solid fa-filter" />
        篩選
      </h3>
      <b-overlay
        :show="loading"
        spinner-variant="primary"
        spinner-type="grow"
        spinner-small
        rounded="sm"
      >
        <b-form class="mt-1" @submit.prevent="onSubmit">
          <b-row>
          
            <!-- <b-col class="mb-1" cols="12">
              <b-form-group
                label="借貸類型："
                label-for="filter_loan_type_id"
              >
                <v-select
                  id="filter_loan_type_id"
                  v-model="update.filter_loan_type_id"
                  :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                  :options="loanTypeOptions"
                  label="name"
                  :reduce="(option) => option.id"
                  placeholder="選擇借貸類型"
                >
                </v-select>
              </b-form-group>
            </b-col> -->

            <b-col class="mb-1" cols="12">
              <div class="d-flex align-items-center mb-1">
                <div style="width: calc(70% + 2px)">供款日期：</div>
                <flat-pickr
                  style="width: 32%"
                  v-model="update.filter_pay_date_from"
                  class="form-control"
                  :config="{
                    allowInput: true,
                    altInput: true,
                    altFormat: 'Y-m-d',
                    dateFormat: 'Y-m-d',
                  }"
                  placeholder="輸入YYYY-MM-DD"
                />

                <div style="width: calc(10% + 2px); text-align: center">-</div>

                <flat-pickr
                  v-model="update.filter_pay_date_to"
                  class="form-control"
                  :config="{
                    allowInput: true,
                    altInput: true,
                    altFormat: 'Y-m-d',
                    dateFormat: 'Y-m-d',
                  }"
                  placeholder="輸入YYYY-MM-DD"
                />
              </div>
              <div class="error-text" v-if="update.filter_pay_date_from && update.filter_pay_date_from > update.filter_pay_date_to">供款日期：開始日期不可大於完結日期</div>
            </b-col>
            <b-col class="mb-1" cols="12">
              <div class="d-flex align-items-center mb-1">
                <div style="width: calc(70% + 2px)">借貸日期：</div>
                <flat-pickr
                  style="width: 32%"
                  v-model="update.filter_loan_date_from"
                  class="form-control"
                  :config="{
                    allowInput: true,
                    altInput: true,
                    altFormat: 'Y-m-d',
                    dateFormat: 'Y-m-d',
                  }"
                  placeholder="輸入YYYY-MM-DD"
                />

                <div style="width: calc(10% + 2px); text-align: center">-</div>

                <!-- filter_loan_date_to-->
                <flat-pickr
                  v-model="update.filter_loan_date_to"
                  class="form-control"
                  :config="{
                    allowInput: true,
                    altInput: true,
                    altFormat: 'Y-m-d',
                    dateFormat: 'Y-m-d',
                  }"
                  placeholder="輸入YYYY-MM-DD"
                />
              </div>
              <div class="error-text" v-if="update.filter_loan_date_from && update.filter_loan_date_from > update.filter_loan_date_to">借貸日期：開始日期不可大於完結日期</div>
            </b-col>

            <b-col class="" cols="12">
              <div class="d-flex align-items-center mb-1">
                <div style="width: calc(70% + 2px)">收據日期：</div>
                <flat-pickr
                  style="width: 32%"
                  v-model="update.filter_receipt_date_from"
                  class="form-control"
                  :config="{
                    allowInput: true,
                    altInput: true,
                    altFormat: 'Y-m-d',
                    dateFormat: 'Y-m-d',
                  }"
                  placeholder="輸入YYYY-MM-DD"
                />

                <div style="width: calc(10% + 2px); text-align: center">-</div>

                <!-- filter_receipt_date_to-->
                <flat-pickr
                  v-model="update.filter_receipt_date_to"
                  class="form-control"
                  :config="{
                    allowInput: true,
                    altInput: true,
                    altFormat: 'Y-m-d',
                    dateFormat: 'Y-m-d',
                  }"
                  placeholder="輸入YYYY-MM-DD"
                />
              </div>
              <div class="error-text" v-if="update.filter_receipt_date_from && update.filter_receipt_date_from > update.filter_receipt_date_to">收據日期：開始日期不可大於完結日期</div>
            </b-col>
            <b-col class="" cols="12">
              <b-form-group label="己交？" label-for="filter_is_paid" label-cols-md="2">
                <b-form-checkbox
                  :checked="update.filter_is_paid"
                  name="filter_is_paid"
                  switch
                  inline
                  v-model="update.filter_is_paid"
                  value="1"
                  style="margin-top: 5px"
                >
                  <!-- {{ update.filter_is_paid ? "己交" : "未還" }} -->
                </b-form-checkbox>
              </b-form-group>
            </b-col>
            <b-col class="mb-1" cols="12">

              <b-form-group label="身分證號碼" label-for="filter_is_paid" label-cols-md="3">
                <b-form-input
                  :id="idFilter"
                  v-model="update.idFilter"
                  placeholder="輸入身分證號碼"
                />
              </b-form-group>
            </b-col>
          </b-row>

          <div class="d-flex mt-2 align-items-center justify-content-center">
            <b-button
              v-ripple.400="'rgba(186, 191, 199, 0.15)'"
              type="button"
              variant="outline-danger"
              class="mr-2"
              @click="hide"
            >
              <feather-icon size="16" class="mr-50" icon="XIcon" />
              取消
            </b-button>
            <b-button
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              variant="primary"
              type="submit"
            >
              <feather-icon size="16" class="mr-50" icon="CheckCircleIcon" />
              確認
            </b-button>
          </div>
        </b-form>
      </b-overlay>
    </div>
  </b-modal>
</template>

<script>
import {
  BButton,
  BModal,
  VBModal,
  BCardText,
  BOverlay,
  BForm,
  BFormGroup,
  BFormInput,
  BFormCheckbox,
  BRow,
  BCol,
  BCard,
  BCardBody,
  BFormInvalidFeedback,
} from "bootstrap-vue";
import vSelect from "vue-select";
import Ripple from "vue-ripple-directive";
import flatPickr from "vue-flatpickr-component";
import { ref, watch, onUnmounted, onMounted } from "@vue/composition-api";
import "animate.css";
import { ValidationProvider, ValidationObserver, localize } from "vee-validate";
import zh_CN from "vee-validate/dist/locale/zh_CN.json";
localize("zh_cn", zh_CN);
import formValidation from "@core/comp-functions/forms/form-validation";
import { required, email } from "@validations";
import axiosIns from "@/libs/axios";

export default {
  components: {
    BButton,
    BModal,
    VBModal,
    BCardText,
    BOverlay,
    BForm,
    BFormGroup,
    BFormInput,
    BFormCheckbox,
    BRow,
    BCol,
    BCardBody,
    BCard,
    BFormInvalidFeedback,
    vSelect,
    flatPickr,
    ValidationProvider,
    ValidationObserver,
  },
  directives: {
    "b-modal": VBModal,
    Ripple,
  },
  methods: {
    hide() {
      this.$bvModal.hide("modal-filter");
    },
    onSubmit() {
      if(
          this.update.filter_pay_date_from && this.update.filter_pay_date_from > this.update.filter_pay_date_to
          || this.update.filter_loan_date_from && this.update.filter_loan_date_from > this.update.filter_loan_date_to
          || this.update.filter_receipt_date_from && this.update.filter_receipt_date_from > this.update.filter_receipt_date_to
      )
      return 
      // this.$emit(
      //   "update:filter_receive_method_id",
      //   this.update.filter_receive_method_id
      // );
      this.$emit("update:filter_is_paid", this.update.filter_is_paid ? 1 : 0);
      this.$emit("update:idFilter", this.update.idFilter);
      this.$emit(
        "update:filter_pay_date_from",
        this.update.filter_pay_date_from
      );
      this.$emit("update:filter_pay_date_to", this.update.filter_pay_date_to);
      this.$emit(
        "update:filter_loan_date_from",
        this.update.filter_loan_date_from
      );
      this.$emit("update:filter_loan_date_to", this.update.filter_loan_date_to);
      this.$emit(
        "update:filter_receipt_date_from",
        this.update.filter_receipt_date_from
      );
      this.$emit("update:filter_receipt_date_to", this.update.filter_receipt_date_to);
      this.hide();
    },
  },
  props: {
    contractStatusesOptions: {},
    filter_is_paid: {},
    idFilter: {},
    filter_pay_date_from: {},
    filter_pay_date_to: {},
    filter_loan_date_from: {},
    filter_loan_date_to: {},
    filter_receipt_date_from: {},
    filter_receipt_date_to: {},
  },
  data() {
    return {
      loading: false,
      update: {
        filter_is_paid: null,
        idFilter: null,
        filter_pay_date_from: null,
        filter_pay_date_to : null,
        filter_loan_date_from: null,
        filter_loan_date_to: null,
        filter_receipt_date_from: null,
        filter_receipt_date_to: null,
      },
    };
  },
  watch: {
    idFilter:{
      handler(new_val) {
        this.update.idFilter = new_val
      }
    },
    filter_pay_date_from:{
      handler(new_val) {
        this.update.filter_pay_date_from = new_val
      }
    },
    filter_pay_date_to:{
      handler(new_val) {
        this.update.filter_pay_date_to = new_val
      }
    },
  },
  
};
</script>

<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";
@import "@core/scss/vue/libs/vue-flatpicker.scss";
</style>
